import CssBaseline from '@mui/material/CssBaseline';
import { BottomAppBar } from './components/bottomAppBar/BottomAppBar';
import { TerveraTheme } from './TerveraTheme';
import { TopAppBar } from './components/topAppBar/TopAppBar';
import NotFound from './components/notFound/NotFound';

import { Route, Routes } from 'react-router-dom';

export const App = () => {
  return (
    <TerveraTheme>
      <TopAppBar />
      <BottomAppBar />
      <CssBaseline />
      <Routes>
        <Route
          path='/'
        />
        <Route
          path='*'
          element={<NotFound />}
        />
      </Routes>
    </TerveraTheme>
  );
};
