import AppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

export const BottomAppBar = () => {
    return (
        <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, }}>
            <Toolbar>
                <Typography variant="body2">&copy; 2023 tervera, llc.</Typography>
                <Link href="/terms.html" target="_blank" variant="body2" sx={{ ml: 2 }}>Terms and Conditions</Link>
                <Link href="/privacy.html" target="_blank" variant="body2" sx={{ ml: 2 }}>Privacy Policy</Link>
            </Toolbar>
        </AppBar>
    );
};
