const NotFound = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
        height: '100vh'
      }}
    >
      <h1
        style={{ color: 'white' }}
      >
        Page not found
      </h1>
    </div>
  );
}

export default NotFound;
