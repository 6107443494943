import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import Typography from '@mui/material/Typography';

export const TopAppBar = () => {
    return (
        <AppBar position="fixed">
            <Toolbar>
                <ShareLocationIcon fontSize="large" sx={{ mr: 1, }} />
                <Typography variant="h1">tervera</Typography>
            </Toolbar>
        </AppBar>
    );
};
