import { type ReactNode } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import earth from './earth.jpg';

export const TerveraTheme = ({ children }: { children?: ReactNode }) => {
  return (
    <ThemeProvider theme={createTheme({
      palette: {
        mode: 'dark',
        primary: {
          main: '#90caf9',
        },
        secondary: {
          main: '#ce93d8',
        },
        background: {
          default: '#121212',
          paper: '#121212',
        },
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              backgroundImage: `url(${earth})`,
              backgroundRepeat: 'no-repeat',
              backgroundPositionX: 'right',
              backgroundPositionY: 'center',
              backgroundAttachment: 'fixed',
              backgroundSize: 'cover',
            }
          }
        }
      }
    })}>
      {children}
    </ThemeProvider>
  );
};
